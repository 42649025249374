<template>
    <div>
  <div class="hello" v-if="iswork==false&&isClWork==false&&isZsctWork===false">
<headers :isbackground="$store.state.isbackground" :iscolor="$store.state.iscolor" :islogored="$store.state.islogored"
         :company="fathercompany"  @workclick="workclick"></headers>
    <mian :componentName="$store.state.components" :company="fathercompany"></mian>
    <footers @footerclick="footerclick" :company="fathercompany"></footers>
  </div>
        <word-home class="hello" v-if="iswork===true" @homeclick="homeclick"></word-home>
        <cl-home class="hello" v-if="isClWork===true" @homeclick="homeclick"></cl-home>
        <zsct-word class="hello" v-if="isZsctWork===true"></zsct-word>
    </div>
</template>

<script>
import headers  from "@/components/header";
import mian from '@/components/mian'
import footers from '@/components/footer'
import wordHome from '@/components/word/index'
import newService from "@/api/newService";
import clHome from "@/components/clword/index.vue";
import zsctWord from "@/components/zsctword/index.vue";
export default {
  name: 'HelloWorld',
  components:{
    headers,
    mian,
    footers,
    wordHome,
      clHome,
      zsctWord
  },
    created() {
        this.getTopCompany()
    },
    data(){
    return {
        fathercompany:{},
      //点击的文字
      isnavtext:'首页',
      // 显示的内容
      componentName:'home',
        iswork:false,
        isClWork:false,
        isZsctWork:false
    }
  },
  mounted() {
      this.checkClWork()
      //进入的时候判断是否是开发文档
      // this.isClWork=true
  },
  methods:{
      checkClWork(){
          let query = location.search;
          let searchParams = new URLSearchParams(query);
          const name = searchParams.get("name");
          const zhSecretTxts = searchParams.get("zhSecretTxt");
          if (zhSecretTxts === "zhSecretTxt"){
              this.$store.state.components="xieyi2"
          }
          if (zhSecretTxts === "zhSoftwareLicense"){
              this.$store.state.components="xieyi1"
          }
          if (name==="isClWork"){
              this.isClWork=true
          }
          if (name==="isZsctWork"){
              this.isZsctWork=true
          }
      },
    footerclick(val){
      console.log(val)
    },
    workclick(val){
      console.log(val)
      if(val.name==='开发文档'){
        this.iswork=true
      }else{
        this.iswork=false
      }
    },
    homeclick(){
      this.iswork=false
        this.isClWork=false
        this.isZsctWork=false
        window.location.href="/"
        // window.open("/")
    },
      getTopCompany(){
          newService.getTopCompany().then((res)=>{
              this.fathercompany=res
          })
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 5.0rem 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 1.0rem;
}
a {
  color: #42b983;
}
</style>
